import React from 'react';
import { Col } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from "../api";
import { Constants, Validation_Constants, Data_Constants } from '../config';
import { functions } from "../functions";
import ReactHtmlParser from 'react-html-parser';
class ContactUs extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         pagetitle: 'Contact us',
         loader: false,
         IsContactUsSuccess: false,
         contactUsData: [],
      };
      this.getRelatedDataForContactUs = this.getRelatedDataForContactUs.bind(this);
   }

   componentWillMount() {
      this.getRelatedDataForContactUs(); // Get business and Subject Matter
   }

   // Get Related contact us form data
   getRelatedDataForContactUs(props = this.props) {
      if (this.state.loader)
         return;
      this.setState({ loader: true });
      api.getRelatedDataForContactUs()
         .then(res => {
            this.setState({ loader: false, contactUsData: res })
         })
         .catch(err => {
            console.log(err);
            this.setState({ loader: false })
         });
   }

   render() {
      //Page_title
      functions.changePageTitle(this.state.pagetitle);
      //Loader
      let loader = functions.getLoader(this.state.loader);
      return (
         <div className="contact-us-page">
            {loader}
            <div className="jumbotron jumbotron-sm">
               <div className="container">
                  <div className="row">
                     <div className="col-sm-12 col-lg-12">
                        <h1 className="h1">
                           Contact us <small>Feel free to contact us</small></h1>
                     </div>
                  </div>
               </div>
            </div>

            <Formik
               initialValues={{ name: '', email: '', businessunit: '', subjectmatter: '', message: '' }}
               onSubmit={(values, { setSubmitting }) => {
                  this.setState({ IsContactUsSuccess: false, loader: true });
                  var PostData = {
                     name: values.name,
                     email: values.email,
                     business: values.businessunit,
                     subjectmatter: values.subjectmatter,
                     message: values.message
                  };
                  api.contactUs(PostData)
                     .then(res => {
                        if (res.data.response.status == '1') {
                           this.setState({ IsContactUsSuccess: true, loader: false });
                        }
                        else {
                           this.setState({ IsContactUsSuccess: false, loader: false });
                        }
                     })
                     .catch(err => {
                        console.log(err);
                        this.setState({ loader: false })
                     });

                  setSubmitting(false);
               }}
               validationSchema={Yup.object().shape({
                  name: Yup.string().trim()
                     .required(Validation_Constants.Empty_Name)
                     .min(2, Validation_Constants.Min_Name)
                     .max(100, Validation_Constants.Max_Name),
                  email: Yup.string().trim()
                     .email(Validation_Constants.Invalid_Email)
                     .required(Validation_Constants.Empty_Email),
                  businessunit: Yup.string().trim()
                     .required(Validation_Constants.Empty_Business_Unit),
                  subjectmatter: Yup.string().trim()
                     .required(Validation_Constants.Empty_Subject_Matter),
                  message: Yup.string().trim()
                     .required(Validation_Constants.Empty_Message)
                     .max(2000, Validation_Constants.Max_Message),
               })}
            >
               {props => {
                  const {
                     values,
                     touched,
                     errors,
                     dirty,

                     isSubmitting,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleReset,
                  } = props;

                  return (
                     <div className="container">
                        <div className="row">
                           <div className="col-md-8">
                              <div className="well well-sm">
                                 {
                                    this.state.IsContactUsSuccess ?

                                       <div class="success-mesage">
                                          <b>Success!</b><br />
                                          <p>Within 24 to 48hrs a Support Team member will contact you. </p>
                                          <br />
                                          Thank you,<br />Turner Strategic Marketing
                                             </div>


                                       :

                                       <form onSubmit={handleSubmit}>
                                          <div className="row">
                                             <div className="col-md-6">

                                                <div className="form-group">
                                                   <label for="name">
                                                      Name</label>
                                                   <input
                                                      id="name"
                                                      placeholder="Name"
                                                      type="text"
                                                      value={values.name}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className={
                                                         errors.name && touched.name ? 'form-control error' : 'form-control'
                                                      }
                                                   />
                                                   {errors.name &&
                                                      touched.name && <span className="form-input-error">{errors.name}</span>}

                                                </div>

                                                <div className="form-group">
                                                   <label for="email">
                                                      Email</label>
                                                   <input
                                                      id="email"
                                                      placeholder="Email"
                                                      type="text"
                                                      value={values.email}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className={
                                                         errors.email && touched.email ? 'form-control error' : 'form-control'
                                                      }
                                                   />
                                                   {errors.email &&
                                                      touched.email && <span className="form-input-error">{errors.email}</span>}
                                                </div>
                                                <div className="form-group">
                                                   <label for="businessunit">
                                                      Business Unit</label>
                                                   <select
                                                      id="businessunit"
                                                      name="businessunit"
                                                      value={values.businessunit}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className={
                                                         errors.businessunit && touched.businessunit ? 'form-control error' : 'form-control'
                                                      }
                                                   >
                                                      <option value="" disabled>--- Select Business Unit ---</option>
                                                      {
                                                         (this.state.contactUsData.length != 0 && this.state.contactUsData.data != 0) ?
                                                            this.state.contactUsData.data.response.data.business.map((value) =>
                                                               <option value={value.id}>
                                                                  {value.name}
                                                               </option>
                                                            ) : ''
                                                      }
                                                   </select>
                                                   {errors.businessunit &&
                                                      touched.businessunit && <span className="form-input-error">{errors.businessunit}</span>}
                                                </div>
                                                <div className="form-group">
                                                   <label for="subject">
                                                      Subject Matter</label>
                                                   <select
                                                      id="subjectmatter"
                                                      name="subjectmatter"
                                                      value={values.subjectmatter}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className={
                                                         errors.subjectmatter && touched.subjectmatter ? 'form-control error' : 'form-control'
                                                      } >
                                                      <option value="" disabled>Please select a topic....</option>
                                                      {
                                                         (this.state.contactUsData.length != 0 && this.state.contactUsData.data != 0) ?
                                                            this.state.contactUsData.data.response.data.subjectmatter.map((value) =>
                                                               <option value={value.id}>
                                                                  {value.name}
                                                               </option>
                                                            ) : ''
                                                      }
                                                   </select>
                                                   {errors.subjectmatter &&
                                                      touched.subjectmatter && <span className="form-input-error">{errors.subjectmatter}</span>}
                                                </div>
                                             </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                   <label for="name">
                                                      Message</label>
                                                   <textarea
                                                      name="message"
                                                      id="message"
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className={
                                                         errors.message && touched.message ? 'form-control error' : 'form-control'
                                                      }
                                                      rows="10"
                                                      cols="25"
                                                      placeholder="Message"
                                                      value={values.message}
                                                   >
                                                   </textarea>
                                                   {errors.message &&
                                                      touched.message && <span className="form-input-error">{errors.message}</span>}
                                                   <p className="char_limit">(2000 character limit)</p>
                                                </div>
                                             </div>
                                             <div className="col-md-12">
                                                <button type="submit" className="btn btn-primary pull-right" id="btnContactUs">
                                                   Send Message</button>
                                             </div>
                                          </div>
                                       </form>
                                 }
                              </div>
                           </div>
                           <div className="col-md-4">
                              <form>
                                 <legend>Contact Us</legend>
                                 <address>
                                 {ReactHtmlParser(Data_Constants.FooterContactUsDatabyAddress)}<br />
                                    <abbr title="Phone">
                                       P:</abbr>
                                       <a className="mob" href={"tel:"+Data_Constants.FooterContactUsDatabyMob}>{Data_Constants.FooterContactUsDatabyMob}</a><br />
                                       {Data_Constants.FooterContactUsDatainDays} | {Data_Constants.FooterContactUsDatainTime}
                                 </address>
                              </form>
                           </div>
                        </div>
                     </div>

                  );
               }}
            </Formik>

         </div>

      )
   }
}


export default ContactUs;
