import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

// Extra library
import { HashRouter, Redirect, Switch } from 'react-router-dom';
import componentQueries from 'react-component-queries';
//End Extra library

// CUSTOM FOR TCDAM
import { LayoutRoute , FrontLayout , UserLayout} from './Tcdam/components/Layout';
import { FrontPage , FrontPagee , FrontPageee , ContactUs , LegalPages , Dashboard} from './Tcdam/pages';
const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
  };
// END CUSTOM FOR TCDAM

class App extends Component {
  render() {
    return (
      <HashRouter basename={getBasename()}>
          <Switch>

                            <LayoutRoute
                            exact
                            path="/"
                            layout={FrontLayout}
                            component={FrontPage}
                            />

                            <LayoutRoute
                            exact
                            path="/:action/:data"
                            layout={FrontLayout}
                            component={FrontPage}
                            />
                            <LayoutRoute
                            exact
                            path="/page1"
                            layout={FrontLayout}
                            component={FrontPagee}
                            />

                            <LayoutRoute
                            exact
                            path="/page2"
                            layout={FrontLayout}
                            component={FrontPageee}
                            />

                            <LayoutRoute
                            exact
                            path="/contact-us"
                            layout={FrontLayout}
                            component={ContactUs}
                            />

                            <LayoutRoute
                            exact
                            path="/legal"
                            layout={FrontLayout}
                            component={LegalPages}
                            />
                            
                            <LayoutRoute
                            exact
                            path="/dashboard"
                            layout={UserLayout}
                            component={Dashboard}
                            />


            <Redirect to="/" />

            </Switch>
        </HashRouter>
      );
    }
  }
  
  const query = ({ width }) => {
    if (width < 575) {
      return { breakpoint: 'xs' };
    }
  
    if (576 < width && width < 767) {
      return { breakpoint: 'sm' };
    }
  
    if (768 < width && width < 991) {
      return { breakpoint: 'md' };
    }
  
    if (992 < width && width < 1199) {
      return { breakpoint: 'lg' };
    }
  
    if (width > 1200) {
      return { breakpoint: 'xl' };
    }
  
    return { breakpoint: 'xs' };
  };
  
  export default componentQueries(query)(App);
