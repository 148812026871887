import React from 'react';
import { Row, Col } from 'reactstrap';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { AssetsDataProcessor } from './../components/Dashboard';

let content;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Home',
            loader: false,
            assestTypeNAseetsTypeCategoriesOptionsData: [],
            bannerData: [],
            featureProjectData: [],
            recentlyAddedData: [],
            projectsData: [],
            historicProjectsData: [],
            templatesData: [],
            clipArtData: [],
        };
    }

    componentWillMount() {

        this.setState({ loader: true });
        var PostData = {
            _token: functions.getToken()
        };
        api.getDashboardData(PostData)
            .then(res => {
                if (res.data.response.status == '1') {
                    this.setState({ loader: false });
                    //assest Type & Aseets Type Categories Options Data
                    if (res.data.response.data.assestTypeNAseetsTypeCategoriesOptionsData.length != 0) {
                        this.setState({ assestTypeNAseetsTypeCategoriesOptionsData: res.data.response.data.assestTypeNAseetsTypeCategoriesOptionsData });
                    }

                    //Banner Data
                    if (res.data.response.data.bannerData.length != 0) {
                        this.setState({ bannerData: res.data.response.data.bannerData });
                    }

                    //featureProjectData
                    if (res.data.response.data.featureProjectData.length != 0) {
                        this.setState({ featureProjectData: res.data.response.data.featureProjectData });
                    }

                    //recentlyAddedData
                    if (res.data.response.data.recentlyAddedData.length != 0) {
                        this.setState({ recentlyAddedData: res.data.response.data.recentlyAddedData });
                    }
                    //projectsData
                    if (res.data.response.data.projectsData.length != 0) {
                        this.setState({ projectsData: res.data.response.data.projectsData });
                    }
                    //historicProjectsData
                    if (res.data.response.data.historicProjectsData.length != 0) {
                        this.setState({ historicProjectsData: res.data.response.data.historicProjectsData });
                    }
                    //templatesData
                    if (res.data.response.data.templatesData.length != 0) {
                        this.setState({ templatesData: res.data.response.data.templatesData });
                    }
                    //clipArtData
                    if (res.data.response.data.clipArtData.length != 0) {
                        this.setState({ clipArtData: res.data.response.data.clipArtData });
                    }
                }
                else {
                    this.setState({ loader: false });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ loader: false })
            });
    }


    render() {
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);

        return (<div className="_theDashboard">
            {loader}

            {/* Banner Section */}

            <section className="homepage">
                <form action="https://www.tcdam.com/search" className="homepage-search-bar" method="get">
                    <div className="search search-banner-top">
                        <div className="search-select dropdown"> <a aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" className="dropdown-toggle dropdown-head" href="javascript:;">All Files</a>
                            <ul id="search-nav" className="dropdown-menu">
                                <li>
                                    <label>
                                        <input label="All Files" message="Search all files…" type="radio" className="all" name="type[]" value="all" />
                                        <span className="lbl padding-8">All Files</span> </label>
                                </li>

                                {/* Loop on Assets Types Menu*/}
                                {(this.state.assestTypeNAseetsTypeCategoriesOptionsData.length != 0) ?
                                   // (this.state.assestTypeNAseetsTypeCategoriesOptionsData).map(item => (
                                        <li>
                                            <label>
                                                <input label="Videos" message="Search for videos…" type="radio" className="childs" name="type[]" value="assets_videos_mp4" />
                                                <span className="lbl padding-8">Videos</span> </label>
                                        </li>
                                    //))
                                    :
                                    ''
                                }
                                {/* End Loop */}
                            </ul>
                        </div>
                        <div className="search-left">
                            <input type="search" className="search-bar" name="keywords" placeholder="Search all files…" autoComplete="off" />
                        </div>
                        <div className="search-box">
                            <button type="submit" className="search-button">
                            </button></div>
                        <span>
                            <ul className="autocomplete" id="ajax_autocomplete"></ul>
                        </span>
                    </div>
                </form>
                {(this.state.bannerData.length != 0)
                    ?
                    <div className="banner" id="bannerid">
                        <div id="myCarousel" className="carousel slide theCarousalSlide" data-ride="carousel" style={{ 'backgroundImage': "url('" + this.state.bannerData.bannerImgSrc + "')" }}>
                            <div className="banner-text">
                                <a className="view" href={"/search?viewBy=picture&amp;tag_identifier=" + this.state.bannerData.bannerTagIdentifier}>
                                    {this.state.bannerData.bannerHeadline}
                                </a>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }
            </section>

            {/* END Banner Section */}



            {/* <!-- feature project section starts here --> */}
            {
                (this.state.featureProjectData.length != 0)
                    ?
                    <div className="feature-project">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1><span className="project-name">Feature Project:</span><br />{this.state.featureProjectData.featureProjectHeadline}</h1>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <div className="project-desc">
                                        <p>{this.state.featureProjectData.featureProjectDescription}</p>
                                        <a href={"/search?viewBy=picture&amp;tag_identifier=" + this.state.featureProjectData.featureProjectTagIdentifier} className="view">View Feature</a> </div>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <div className="project-image">
                                        <img src={this.state.featureProjectData.featureProjectImgSrc} alt="DAM Feature: Project Name" className="img-responsive" /> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }
            {/* <!-- feature project section ends here -->  */}


            {/* Recently added section */}
            {
                (this.state.recentlyAddedData.length != 0)
                    ?
                    <AssetsDataProcessor data={this.state.recentlyAddedData} class="recent-uploads" title="Recently Added" view_all_link="" view_all_title="" hr_line="true" />
                    :
                    ''
            }
            {/* End Recently added section */}

            {/* Recently added projects */}
            {
                (this.state.projectsData.length != 0)
                    ?
                    <AssetsDataProcessor data={this.state.projectsData} class="recent-projects" title="Projects" view_all_link="#View all projects" view_all_title="View all projects" hr_line="true" />
                    :
                    ''
            }
            {/* End Recently added projects */}

            {/* Recently added projects */}
            {
                (this.state.projectsData.length != 0)
                    ?
                    <AssetsDataProcessor data={this.state.projectsData} class="recent-projects" title="Projects" view_all_link="#View all projects" view_all_title="View all projects" hr_line="true" />
                    :
                    ''
            }
            {/* End Recently added projects */}

            {/* historic Projects Data */}
            {
                (this.state.historicProjectsData.length != 0)
                    ?
                    <AssetsDataProcessor data={this.state.historicProjectsData} class="recent-historic" title="Historic Projects" view_all_link="#View all historic projects" view_all_title="View all historic projects" hr_line="true" />
                    :
                    ''
            }
            {/* End historic Projects Data */}

            {/* templates Data */}
            {
                (this.state.templatesData.length != 0)
                    ?
                    <AssetsDataProcessor data={this.state.templatesData} class="recent-templates" title="Templates" view_all_link="#View all templates" view_all_title="View all templates" hr_line="true" />
                    :
                    ''
            }
            {/* End templates Data */}

            {/* clipArt Data */}
            {
                (this.state.templatesData.length != 0)
                    ?
                    <AssetsDataProcessor data={this.state.clipArtData} class="recent-cliparts home-footer-top-space" title="Clip Art" view_all_link="#View all clip art" view_all_title="View all clip art" hr_line="" />
                    :
                    ''
            }
            {/* End clipArt Data */}


        </div>
        );
    }
};

export default Dashboard;
