import React from 'react';
import UserHeader from './UserHeader';
import UserFooter from './UserFooter';

const UserLayout = ({ children, ...restProps }) => (
  <div className="front_layout home"  {...restProps}>
             <div className="main-section"> 
              <UserHeader />
                  {children}
              <UserFooter />
            </div>
  </div>
);

export default UserLayout;
