import React from 'react';
import { Row, Col } from 'reactstrap';
import MedeByLogo from '../../assets/images/imgpsh_fullsize.png';
import { Data_Constants } from '../../config';
import { api } from "../../api";
import { functions } from "../../functions";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

let content;

class UserFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DAMMetrics: []
        };
    }

    componentWillMount() {
        var PostData = {
            _token: functions.getToken()
        };
        api.getUserFooterData(PostData)
            .then(res => {
                if (res.data.response.status == '1') {
                    this.setState({ loader: false });
                    //DAMMetrics
                    if (res.data.response.data.DAMMetrics.length != 0) {
                        this.setState({ DAMMetrics: res.data.response.data.DAMMetrics });
                    }
                }
                else{
                    this.setState({ loader: false });
                }
        })
        .catch(err => {
            console.log(err);
            this.setState({ loader: false })
        });
    }

    
    render() {

        return (
        <footer className="user_footer the_footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5 col-xs-12">
                        <div className="footer-block1">
                            <h2>Search for Digital Assets Anywhere</h2>
                            <ul className="footer-col">
                                <li>
                                    <h4>TCDam</h4>
                                </li>
                                <li  className="engage-link">
                                    <h4>Legal</h4>
                                    <ul>
                                        <li><Link to="legal?page=privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="legal?page=membership-agreement">Membership Agreement</Link></li>
                                        <li><Link to="legal?page=terms-conditions">Terms & Conditions</Link></li>
                                    </ul>
                                </li>
                                {/* <?php if ($this->session->userdata('front_user')['user_type'] != 'V') { ?> */}
                                    <li>
                                        <h4>Engage</h4>
                                        <ul>
                                            <li><a title="Submit Assets" href="/admin/assets/create/assets">Submit Asset(s)</a></li>
                                        </ul>

                                    </li>
                                {/* // <?php } ?> */}
                            </ul>

                        </div>
                    </div>
                    {
                    (this.state.DAMMetrics.length != 0)
                    ?
                    <div className="col-sm-4 col-xs-12">
                        <div className="footer-block2">
                            <h2>DAM Metrics</h2>
                            <ul className="footer-col1">
                                <li>
                                    <h4>Total Assets</h4>
                                        <span className="dum-total">{this.state.DAMMetrics.totalAssets}</span>
                                </li>
                                <li>
                                    <h4>DAM Views</h4>
                                    <span className="dam-viewers">{this.state.DAMMetrics.DAMViews}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :
                    ''
                    }
                    <div className="col-sm-3 col-xs-12 footer-block3">
                        <h2>Contact Us</h2>
                        <p className="contact-us-text">We are committed to improving TCDam.  Please <Link to="contact-us">contact us</Link> with your questions and/or comments. We are always happy to hear from you.</p>
                            <div className="made-in-new"><img src={MedeByLogo} /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <p className="copyright">{ReactHtmlParser(Data_Constants.FooterNotice)}</p>
                    </div>
                </div>
            </div>
      </footer>
        );
    }
};

export default UserFooter;
