import React from 'react';
import { Row, Col } from 'reactstrap';
import { Constants, Validation_Constants } from '../config';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from "../api";
import { functions } from "../functions";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';

let content;

class FrontPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Home',
            errors: '',
            IsNewMember: false,
            loader: false,
            signUpData: [],
            avlRegions: <option value="" disabled>--- Select Region ---</option>,
            avlRegionsId: ''
        };
        this.getRelatedDataForSignUp = this.getRelatedDataForSignUp.bind(this);
        this.handleBusinessUnitFieldBlur = this.handleBusinessUnitFieldBlur.bind(this);
        this.openPopupWindow=this.openPopupWindow.bind(this);

    }

   
    componentWillMount() {
        this.getRelatedDataForSignUp(); // Get business,regions and departments
    }

    //For open popup window in new tab
    openPopupWindow = (url)=> {
        // e.preventDefault();
        functions.openPopupWindow(url);
    }

    // Sign up Form
    getSignupForm() {
        return (<Formik
            initialValues={{ firstname: '', lasttname: '', email: '', passwordd: '', repassword: '', businessunit: '', region: '', department: '' }}
            onSubmit={(values, { setSubmitting }) => {
                this.setState({ errors: '', loader: true });


                var PostData = {
                    first_name: values.firstname,
                    last_name: values.lasttname,
                    email: values.email,
                    password: values.passwordd,
                    business: values.businessunit,
                    region: this.state.avlRegionsId,
                    department: values.department
                };
                api.signUp(PostData)
                    .then(res => {
                        if (res.data.response.status == '1') {
                            this.setState({ IsNewMember: true, loader: false });
                            window.location.href = Constants.Partner_URL;
                        }
                        else {
                            this.setState({ loader: false });
                            this.setState({ errors: Validation_Constants.Account_Already_Exists });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({ loader: false })
                    });

                setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
                firstname: Yup.string().trim()
                    .required(Validation_Constants.Empty_First_Name),
                lasttname: Yup.string().trim()
                    .required(Validation_Constants.Empty_Last_Name),
                email: Yup.string().trim()
                    .email(Validation_Constants.Invalid_Email)
                    .required(Validation_Constants.Empty_Email),
                passwordd: Yup.string()
                    .required(Validation_Constants.Empty_Password)
                    .min(8, Validation_Constants.Min_Password)
                    .matches(/.*[0-9].*/, Validation_Constants.Regex_Number_Password)
                    .matches(/.*[!@#$%^&*(),.?":{}|<>].*/, Validation_Constants.Regex_Special_Char_Password)
                    .max(100, Validation_Constants.Max_Password)
                ,
                repassword: Yup.string()
                    .required(Validation_Constants.Empty_RePassword)
                    .test('passwords-match', Validation_Constants.Invalid_RePassword, function (value) {
                        return this.parent.passwordd === value;
                    }),
                businessunit: Yup.string().trim()
                    .required(Validation_Constants.Empty_Business_Unit),
                department: Yup.string().trim()
                    .required(Validation_Constants.Empty_Department),
            })}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,

                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                } = props;

                return (

                    <div className="bam">
                        <div className="bam-left">
                            <h2> Become A Member</h2>
                            <form id="form_login" className="formDetail" onSubmit={handleSubmit}>
                                <div className="form-grp">

                                    <input
                                        id="firstname"
                                        placeholder="First Name"
                                        type="text"
                                        value={values.firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.firstname && touched.firstname ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    <span>First Name*</span>
                                    {errors.firstname &&
                                        touched.firstname && <span className="form-input-error">{errors.firstname}</span>}

                                </div>

                                <div className="form-grp">

                                    <input
                                        id="lasttname"
                                        placeholder="Last Name"
                                        type="text"
                                        value={values.lasttname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={

                                            errors.lasttname && touched.lasttname ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    <span>Last Name*</span>
                                    {errors.lasttname &&
                                        touched.lasttname && <span className="form-input-error">{errors.lasttname}</span>}

                                </div>

                                <div className="form-grp">

                                    <input
                                        id="email"
                                        placeholder="Email"
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.email && touched.email ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    <span> Email*</span>
                                    {errors.email &&
                                        touched.email && <span className="form-input-error">{errors.email}</span>}
                                    {this.state.errors ? <span className="form-input-error">{this.state.errors}</span> : ''}

                                </div>

                                <div className="form-grp">

                                    <input
                                        id="passwordd"
                                        placeholder="Password"
                                        type="password"
                                        value={values.passwordd}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.passwordd && touched.passwordd ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    <span>Password*</span>
                                    {errors.passwordd &&
                                        touched.passwordd && <span
                                            className="form-input-error">{errors.passwordd}</span>}

                                </div>

                                <div className="form-grp">

                                    <input
                                        id="repassword"
                                        placeholder="Re-Type Password"
                                        type="password"
                                        value={values.repassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.repassword && touched.repassword ? 'form-control error' : 'form-control'
                                        }
                                    />
                                    <span> Re-Type Password* </span>
                                    {errors.repassword &&
                                        touched.repassword && <span className="form-input-error">{errors.repassword}</span>}

                                </div>

                                <button type="submit" className="display-none" disabled={isSubmitting}>
                                    Create Account
                                        </button>
                            </form>
                            <p> Must be 8 characters, contain one<br /> number and one special character</p>
                        </div>
                        <div className="bam-right">
                            <p> Only employees of Turner Construction Company<br /> subsidaires, affiliates, or parent company may register.</p>
                            <form id="form_login_1" className="formDetail" onSubmit={handleSubmit}>
                                <div className="form-grp">

                                    <select
                                        id="businessunit"
                                        name="businessunit"
                                        value={values.businessunit}
                                        onChange={handleChange}
                                        // onChange={this.handleBusinessUnitFieldBlur}
                                        // onBlur={handleBlur}
                                        onBlur={this.handleBusinessUnitFieldBlur}
                                        className={
                                            errors.businessunit && touched.businessunit ? 'form-control error' : 'form-control'
                                        }
                                    >
                                        <option value="" disabled>--- Select Business Unit ---</option>
                                        {
                                            (this.state.signUpData.length != 0 && this.state.signUpData.data != 0) ?
                                                this.state.signUpData.data.response.data.business.map((value) =>
                                                    <option value={value.id} data-region_id={value.region_id}>
                                                        {value.name}
                                                    </option>
                                                ) : ''
                                        }
                                    </select>
                                    <span>Business Unit, Location or Comapny*</span>
                                    {errors.businessunit &&
                                        touched.businessunit && <span className="form-input-error">{errors.businessunit}</span>}

                                </div>

                                <div className="form-grp">

                                    <select
                                        id="region"
                                        name="region"
                                        value={values.region}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.region && touched.region ? 'form-control error' : 'form-control'
                                        }
                                    >
                                        {this.state.avlRegions}
                                    </select>
                                    <span>Region</span>
                                    {errors.region &&
                                        touched.region && <span className="form-input-error">{errors.region}</span>}

                                </div>

                                <div className="form-grp">

                                    <select
                                        id="department"
                                        name="department"
                                        value={values.department}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.department && touched.department ? 'form-control error' : 'form-control'
                                        }
                                    >
                                        <option value="" disabled>--- Select Department ---</option>
                                        {
                                            (this.state.signUpData.length != 0 && this.state.signUpData.data != 0) ?
                                                this.state.signUpData.data.response.data.departments.map((value) =>
                                                    <option value={value.id}>
                                                        {value.name}
                                                    </option>
                                                ) : ''
                                        }
                                    </select>
                                    <span> Department*</span>
                                    {errors.department &&
                                        touched.department && <span className="form-input-error">{errors.department}</span>}

                                </div>

                                <button type="submit" disabled={isSubmitting}> Create Account </button>
                            </form>
                            <p className="terms">By registering a TCDam Account you accept TCDam’s  <Link to="#" onClick={() =>this.openPopupWindow('#legal?page=terms-conditions')}>Terms of Use</Link>, <Link to="#" onClick={() =>this.openPopupWindow('#legal?page=privacy-policy')}>Privacy Policy </Link>, <Link to="#" onClick={() =>this.openPopupWindow('#legal?page=membership-agreement')}>Member Agreement</Link>.</p>


                        </div>
                    </div>
                );
            }}
        </Formik>);
    }

    // Get Related signup form data
    getRelatedDataForSignUp(props = this.props) {
        if (this.state.loader)
            return;
        this.setState({ loader: true });
        api.getRelatedDataForSignUp()
            .then(res => {
                this.setState({ loader: false, signUpData: res })
            })
            .catch(err => {
                console.log(err);
                this.setState({ loader: false })
            });

    }

    // on bussiness blur get related region
    handleBusinessUnitFieldBlur = (e) => {
        // alert(e.target[e.target.selectedIndex].getAttribute("data-region_id"));
        var avlRegions = <option value="" disabled>--- Select Region ---</option>;
        var avlRegionsId = "";
        this.state.signUpData.data.response.data.regions.map(function (value) {
            if (e.target[e.target.selectedIndex].getAttribute("data-region_id") == value.id) {
                avlRegionsId = value.id;
                avlRegions = <option value={value.id} data-region_id={value.region_id}>{value.name}</option>;
            }
        }
        );
        this.setState({ avlRegions, avlRegionsId })
    }



    render() {

        if (localStorage.getItem('login_user_sesion') != null) {
            return <Redirect to='/dashboard' />
         }

        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);

        if (this.state.IsNewMember === true) {
            content = <div className="bam new_member">{Validation_Constants.Account_Created_Success}</div>
        }
        else {
            content = this.getSignupForm();
        }
        return (
            <section className="become-member">
                <div className="container-fluid">
                    <Row>
                        <Col md={12} sm={12}>
                            {loader}
                            {content}
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
};

export default FrontPage;
