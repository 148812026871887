import React from 'react';
import { Constants} from '../config';

export const functions = {
   getParamValuesByName,
   getUrlSlice,
   changePageTitle,
   getLoader,
   openPopupWindow,
   getToken,
}

//For read query string by name
function getParamValuesByName(querystring) {
   var qstring = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
   for (var i = 0; i < qstring.length; i++) {
      var urlparam = qstring[i].split('=');
      if (urlparam[0] == querystring) {
         return urlparam[1];
      }
   }
}

//For read URL with slice
function getUrlSlice(sep = '/', slice = '') {
   var arr = window.location.href.split(sep)
   return (arr.slice(Math.max(arr.length - slice, 1)));
}

//For change page title per component
function changePageTitle(page_title = 'TCDAM') {
   document.title = page_title;
   //  alert(document.title); // displays "Hello World!"
   //  document.title = "Goodbye World!";
   //  alert(document.title); // displays "Goodbye World!"
}

//For Site Loader
function getLoader(status=false) {
   var loader='';
   document.body.classList.remove('modal-open');
   if (status) {
      document.body.classList.add('modal-open');
      loader = <div id="loader">
         <img src={Constants.Loader} alt="loading.." />
      </div>
   }
   return (loader);
}

//For open new popup window
function openPopupWindow(e) {
   // alert("==>"+e);
   var n = window.innerHeight, o = window.innerWidth;
   return n -= 14.5 * n / 100, o <= 1366 ? (o -= 24 * o / 100, n -= 23 * n / 100) : o < 1920 ? (n -= 4 * n / 100, o -= 35 * o / 100) : o >= 1920 && (n -= 4 * n / 100, o -= 45 * o / 100), window.open(e, "", "fullscreen=no,toolbar=yes, scrollbars=yes, width = " + o + " ,height= " + n + ",top=57,left=60")
}


//For get Token
function getToken(e) {
   var localStorageData = JSON.parse(localStorage.getItem('login_user_sesion'));
   return localStorageData.token;
}



export default functions;