import React from 'react';

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    FormFeedback,
} from 'reactstrap';

const FrontPageee = () => {
    return (
        <section className="page2">
            <div className="container-fluid">
                    <Col md={12} sm={12}>
                        <div className="page2_inner">
                            <div className="jumbotron text-center">
                                <h1>My First Bootstrap Page 2</h1>
                                <p>Resize this responsive page to see the effect!</p>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <Col sm={4}>
                                        <h3>Column 1</h3>
                                        <p>Lorem ipsum dolor..</p>
                                        <p>Ut enim ad..</p>
                                    </Col>
                                    <Col sm={4}>
                                        <h3>Column 2</h3>
                                        <p>Lorem ipsum dolor..</p>
                                        <p>Ut enim ad..</p>
                                    </Col>
                                    <Col sm={4}>
                                        <h3>Column 3</h3>
                                        <p>Lorem ipsum dolor..</p>
                                        <p>Ut enim ad..</p>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </Col>
            </div>
        </section>
    );
};

export default FrontPageee;
