import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../config';
import { Col } from 'reactstrap';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignOut: false
    };
    this.SignOut = this.SignOut.bind(this);
  }
  SignOut(e) {
    e.preventDefault();
    localStorage.clear();
    this.setState({ isSignOut: true });
  }
  componentWillMount() {
    if (localStorage.getItem('login_user_sesion') == null) { // If unauthrized user
      return <Redirect to='/' />
    }
  }
  render() {
    // User Auth
    if (this.state.isSignOut === true) { // If sigout
      window.location.href = Constants.Partner_URL;
    }
    else if (localStorage.getItem('login_user_sesion') == null) { // If unauthrized user
      return <Redirect to='/' />
    }
    return (
      <header className="main-header">
        <div className="header-main">
          <Col md={12} sm={12}>
            <div className="logo logo-center-dis">
              <Link to="/">
                <img src={Constants.SiteLogo} alt="logo" />
              </Link>
              <Link to="#/signout" className="sign-out" onClick={this.SignOut}>
                Sign-out
              </Link>
            </div>
            <div className="extra_menus">
            <ul className="nav-icons">
              <li>
                  <a title="Favorite" href="#" className="fav"><i className="fa fa-heart fa-lg"></i></a><br/>
                  <center><span id="fav-item-counting" className="counting fav-couting">0</span></center>
              </li>
              <li>
                  <a title="Cart" href="#" className="basket"><i className="fa fa-shopping-basket fa-lg"></i></a>                                        <center><span id="cart-item-counting" className="cart-counting counting">3</span></center>
              </li>
              <li>
                  <a title="Submit Assets" href="/admin/assets/create/assets" className="basket"><i className="fas fa-cloud-upload-alt"></i></a>
              </li>
              <li><Link title="Contact us" to="contact-us" className="basket"><i className="fa fa-envelope"></i></Link></li>
              <li className="dropdown"><a title="Account settings" aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" className="dropdown-toggle account" href="#"><i className="fas fa-cog"></i></a></li>
            </ul>
            </div>
          </Col>
        </div>
      </header>
    );
  }
}

export default UserHeader;
