import React from "react";
import { Constants } from '../config';
import axios from 'axios';
export const api = {
  login,
  forgetPassword,
  passwordReset,
  getRelatedDataForSignUp,
  signUp,
  contactUs,
  getRelatedDataForContactUs,
  getDynamicPageBySlug,
  getDashboardData,
  getUserFooterData,
}

//For User Login
function login(PostData = {}) {
  return axios.post(Constants.Login_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
      //alert(Validation_Constants.API_Server_Not_Responding);
    });
}

//For forget password recover
function forgetPassword(PostData = {}) {
  return axios.post(Constants.Recover_Password_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

//For Password Reset
function passwordReset(PostData = {}) {
  return axios.post(Constants.Reset_Password_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

// For get data related to sigup procedure
function getRelatedDataForSignUp() {
  return axios.get(Constants.Get_Signup_Data_API)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

//For user Signup
function signUp(PostData = {}) {
  return axios.post(Constants.Signup_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

//For Contact Us
function contactUs(PostData = {}) {
  return axios.post(Constants.ContactUs_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

// For get data related to contact us procedure
function getRelatedDataForContactUs() {
  return axios.get(Constants.Get_ContactUs_Data_API)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

// For get dynamic page data related to slug
function getDynamicPageBySlug(PostData = {}) {
  return axios.post(Constants.DynamicPageBySlug_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

// For get dynamic Dasboard
function getDashboardData(PostData = {}) {
  return axios.post(Constants.Get_Dashboard_Data_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

// For get User Footer Data
function getUserFooterData(PostData = {}) {
  return axios.post(Constants.Get_UserFooter_Data_API,
    PostData
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}


export default api;