import SiteLogo from '../assets/images/LOGO.png';
import Loader from '../assets/images/loader.gif';
  const Constants = {
    SiteLogo: SiteLogo,
    Loader: Loader,
    Partner_URL: 'http://www.turnerconstruction.com/office-network/new-york',
    Login_API: 'http://tcdam1.csdevhub.com/admin/api_login',
    Get_Signup_Data_API: 'http://tcdam1.csdevhub.com/admin/api_registerPrerequisite',
    Signup_API: 'http://tcdam1.csdevhub.com/admin/api_register',
    Recover_Password_API: 'http://tcdam1.csdevhub.com/admin/api_recoverPassword',
    Reset_Password_API: 'http://tcdam1.csdevhub.com/admin/api_resetPassword',
    ContactUs_API: 'http://tcdam1.csdevhub.com/admin/api_contactUs',
    Get_ContactUs_Data_API: 'http://tcdam1.csdevhub.com/admin/api_contactusPrerequisite',
    DynamicPageBySlug_API: 'http://tcdam1.csdevhub.com/admin/api_getDynamicPageBySlug',
    Get_Dashboard_Data_API: 'http://tcdam1.csdevhub.com/admin/api_getDahboardData',
    Get_UserFooter_Data_API: 'http://tcdam1.csdevhub.com/admin/api_getUserFooterData',
    
  };

export default Constants;