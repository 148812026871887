const Validation_Constants = {
  API_Server_Not_Responding: "Currently Tcdam not responding for this action.\nPlease try again later.",
  
  Empty_Username: "Please enter a username.",
  Invalid_Username: "Username is not valid.",
  Min_Username: "Your username must consist of at least 2 characters.",
  Max_Username: "Your username must consist of at below 100 characters.",

  Empty_Password: "Please provide a password.",
  Min_Password: "Your password must be at least 8 characters long.",
  Max_Password: "Your password must be at below 100 characters",
  Regex_Number_Password: "One number is required.",
  Regex_Special_Char_Password: "One special character is required.",

  Empty_RePassword: "Please provide a confirm password.",
  Invalid_RePassword: "Passwords do not match.",

  Empty_Email: "Please enter email address.",
  Invalid_Email: "Please enter a valid email address.",

  Account_Recover_Error: "This e-mail is not associated with an account.Please try again, or contact your TCDam Manager.",
  Account_Recover_Success: "A link to rest your password has been sent to the e-mail account on file. This link will expire in 15-minutes.",
  Account_Already_Exists: "Account already exists.",
  Account_Created_Success: "Thank you for registering an account!. Please allow our team 24hrs to review your information.",

  Empty_First_Name: "Please enter your first name.",

  Empty_Last_Name: "Please enter your last name.",

  Empty_Business_Unit: "Please enter your business Unit.",

  Empty_Department: "Please enter your department.",

  Expiry_Password_link: "This Is Expiry Link. Please Request For A New Code",

  Empty_Name: "Please enter a name.",
  Min_Name: "Your name must consist of at least 2 characters.",
  Max_Name: "Your name must consist of at below 100 characters.",

  Empty_Subject_Matter: "Please enter your subject matter.",

  Empty_Message:"Please enter your message.",
  Max_Message: "Your message must consist of at below 2000 characters.",
};

export default Validation_Constants;