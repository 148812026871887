import React from 'react';
import { Row, Col } from 'reactstrap';
import { Constants, Validation_Constants } from '../config';
import { api } from "../api";
import { functions } from "../functions";
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

let content;

class LegalPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagetitle: 'Legal',
            currentPage: '',
            currentHtml: '',
            parentHeadline: '',
            currentHeadline: '',
            sidebarPages: "",
            updatedAt: ""
        };
    }

    componentWillMount() {
        //get current page request
        var currentPageSlug = functions.getParamValuesByName("page");
        this.setState({ currentPage: currentPageSlug });

        if (currentPageSlug != "") {
            this.setState({ loader: true});
            var PostData = {
                page_slug: currentPageSlug
            };
            api.getDynamicPageBySlug(PostData)
            .then(res => {
                if (res.data.response.status == '1') {
                    this.setState({ loader: false });
                    if(res.data.response.data.currentPageData.content!=""){
                        this.setState({ currentHeadline:res.data.response.data.currentPageData.headline , updatedAt: res.data.response.data.currentPageData.updated_at , currentHtml: res.data.response.data.currentPageData.content, parentHeadline :res.data.response.data.currentPageData.parentHeadline });
                        var theSidebarPages =  "";
                        res.data.response.data.allPagesData.map(data => (
                            
                            theSidebarPages=theSidebarPages+"<li class='sidebarPagesMenuItem "+((currentPageSlug==data.slug) ? 'active' : '') +" terms-link'><a href='#/legal?page="+data.slug+"' >"+data.title+"</a></li>"
                          ));
                          this.setState({sidebarPages: theSidebarPages});
                    }
                }
                else {
                    this.setState({ loader: false, currentHeadline:"" ,updatedAt: "",currentHtml: "" , parentHeadline: "" , sidebarPages: "" });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ loader: false, currentHeadline:"" ,updatedAt: "", currentHtml: "" , parentHeadline : "" , sidebarPages: "" })
            });
        }
    }

    componentDidMount(){
        window.addEventListener("hashchange", e =>  this.componentWillMount() );
    }

    
    render() {
        //Page_title
        functions.changePageTitle(this.state.pagetitle);

        //Loader
        let loader = functions.getLoader(this.state.loader);
        
        return (<div className="_theLegalPage">
                <section className="legal-pages">
                    <div className="container">
                        <Row>
                            <Col md={12} sm={12}>
                                {loader}
                                {(this.state.currentHtml!="") ? 
                                

                                <div className="innerPage">
                                    <div className="mainTitle">
                                        <h1>
                                            {ReactHtmlParser(this.state.parentHeadline)}
                                        </h1>
                                    </div>
                                    <Col md={2} sm={2}>
                                    <div className="sidebarPagesMainSection">
                                        <ul className="sidebarPagesMenu">
                                            {ReactHtmlParser(this.state.sidebarPages)}
                                        </ul>
                                    </div>
                                    </Col>
                                    <Col md={10} sm={10}>
                                        <h5 className="theTitle">{ReactHtmlParser(this.state.currentHeadline)}</h5>
                                        <label className="theLastUpdate">Last Updated:<i> {ReactHtmlParser(this.state.updatedAt)} </i></label>
                                        <div className="theContent">
                                            {ReactHtmlParser(this.state.currentHtml)}
                                        </div>
                                    </Col>
                                </div>
                                

                                : <div className='innerPage'>
                                    <h5 className='notFound'>Not Found..!</h5>
                                </div>
                            }
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        );
    }
};

export default LegalPages;
